import { graphql } from 'gatsby';
import React from 'react';

export default function HomePage({ data }) {
  return <></>;
}

export const query = graphql`
  query {
    navInfo: allSanityInfos {
      nodes {
        categories
      }
    }

    navLabo: allSanityLaboratory {
      nodes {
        categories
      }
    }

    navStudio: allSanityStudio {
      nodes {
        categories
      }
    }
    queryInfos: allSanityInfos {
      nodes {
        categories
        _updatedAt
        slug {
          current
        }
        _rawText
      }
    }

    queryLaboratory: allSanityLaboratory {
      nodes {
        categories
        _updatedAt
        project
        date
        slug {
          current
        }
        imageshomepage {
          alt
          asset {
            fluid(maxWidth: 1500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    queryStudio: allSanityStudio {
      nodes {
        categories
        _updatedAt
        project
        date
        slug {
          current
        }
        imageshomepage {
          alt
          asset {
            fluid(maxWidth: 1500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    # seo: sanitySeo(title: { eq: "Hompage" }) {
    #   id
    #   image {
    #     asset {
    #       fluid(maxWidth: 700) {
    #         ...GatsbySanityImageFluid
    #       }
    #     }
    #   }
    #   title
    #   titlePage
    #   description
    # }
  }
`;
